import React, { useState, useEffect } from "react";
import "./CookieBanner.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CookieBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isEU, setIsEU] = useState<boolean>(false);
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const response = await fetch("https://get.geojs.io/v1/ip/geo.json");
        const data = await response.json();
        if (data && data.country_code === "EU") {
          setIsEU(true);
        }
      } catch (error) {
        console.error("Failed to fetch location data:", error);
      }
    };

    checkLocation();
  }, []);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (!cookiesAccepted && isEU) {
      setIsVisible(true);
    }
  }, [isEU]);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setIsVisible(false);
  };

  const handleNavigate = () => {
    navigate("/privacy");
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        {t("cookies.text")}{" "}
        <span
          style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          onClick={handleNavigate}
        >
          {t("cookies.more")}
        </span>
      </p>
      <button onClick={handleAccept}>{t("cookies.accept")}</button>
    </div>
  );
};

export default CookieBanner;
