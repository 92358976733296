import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../helpers/axiosConfig";

export const registerAccount = createAsyncThunk(
    'account/registerAccount',
    async ({ user, navigate }, { rejectWithValue }) => {
        try {
            const accountData = new FormData();
            accountData.append('email', user.email);
            accountData.append('password', user.password);
            accountData.append('password2', user.password2);
            const res = await api.post(`/api/users/register/`, accountData);
            console.log(res)
            if (res.status === 201) {
                return { res, navigate, user };
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.email) {
                return rejectWithValue(err.response.data.email[0]);
            } else {
                return "Ошибка при регистрации"
            }
        }
    }
);

export const confirmAccount = createAsyncThunk(
    'account/confirmAccount',
    async ({ verificationCode, navigate }, { rejectWithValue }) => {
        try {
            const keyData = new FormData();
            keyData.append('code', verificationCode);
            const res = await api.post(`/api/users/confirm/`, keyData);
            if (res.status === 200) {
                return { res, navigate }
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
);

export const resendCodeForConfirm = createAsyncThunk(
    'account/resendCodeForNewPassword',
    async ({ emailToSubmit }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('email', emailToSubmit);
            const res = await api.post(`/api/users/resend_code/`, formData);
            if (res.status === 201) {
                return { res }
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
)

export const getUserData = createAsyncThunk(
    'account/getUserData',
    async () => {
        try {
            const { data } = await api.get("/api/users/profile/");
            return { data };
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
);

export const getUserStatusData = createAsyncThunk(
    'account/getUserStatusData',
    async () => {
        try {
            const { data } = await api.get("/api/users/profile/");
            return { data };
        } catch (err) {
            console.log(err)
            throw err;
        }
    }
)

export const loginAccount = createAsyncThunk(
    'account/loginAccount',
    async ({ user, navigate }, { rejectWithValue }) => {
        try {
            const accountData = new FormData();
            accountData.append('email', user.email);
            accountData.append('password', user.password);
            const res = await api.post("/api/users/login/", accountData);
            console.log(res)
            if (res.status === 200) {
                return { res, navigate, user };
            }
        } catch (err) {
            console.log("Ошибка при входе в систему:", err);
            if (err.response.data.detail) {
                return rejectWithValue(err.response.data.detail);
            } else if (err.response.data.error) {
                return rejectWithValue(err.response.data.error);
            } else if (err.response.message) {
                return rejectWithValue(err.response.message);
            } else {
                return rejectWithValue("Не удалось войти в аккаунт")
            }
        }
    }
);

export const changePassword = createAsyncThunk(
    'account/changePassword',
    async (password, thunkAPI) => {
        try {
            const accountData = new FormData();
            accountData.append('old_password', password.old_password);
            accountData.append('new_password1', password.new_password1);
            accountData.append('new_password2', password.new_password2);
            const { data } = await api.patch(`/api/users/change_password/`, accountData);
            return { data };
        } catch (err) {
            console.log(err);
            if (err.response && err.response.data && err.response.data.message && err.response.data.message[0] === "Старый пароль неверный") {
                return thunkAPI.rejectWithValue(err.response.data.message[0]);
            }
        }
    }
);

export const changeEmail = createAsyncThunk(
    'account/changeEmail',
    async (email) => {
        try {
            const emailData = new FormData();
            emailData.append('email', email.new_email);
            const res = await api.post(`/api/email/`, emailData);
            if (res.status === 200) {
                console.log(res.data)
                alert(res.data);
                return email.new_email;
            }
        } catch (err) {
            console.log(err);
        }
    }
);

export const confirmEmailChange = createAsyncThunk(
    'account/confirmEmailChange',
    async ({ verificationCode, navigate }) => {
        try {
            const { data } = await api.get(`/api/user/${verificationCode}/`);
            if (data.message === "Email подтвержден") {
                alert('Email подтвержден');
                return { navigate };
            }
        } catch (err) {
            console.log(err);
        }
    }
)

export const updateProfile = createAsyncThunk(
    'account/updateProfile',
    async ({ profile, navigate }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            const profileData = new FormData();
            if (profile.image instanceof File) {
                profileData.append('image', profile.image);
            }
            profileData.append('first_name', profile.first_name);
            profileData.append('last_name', profile.last_name);
            profileData.append('gender', profile.gender);
            profileData.append('date', profile.date);
            profileData.append('region', profile.region);
            const res = await api.patch(`/api/users/profile/update/`, profileData, config);
            console.log(res)
            if (res.status === 200) {
                return { res, navigate }
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data)
        }
    }
);


export const sendEmail = createAsyncThunk(
    'account/sendEmail',
    async ({ email, navigate }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('email', email);
            const res = await api.post(`/api/users/password_reset/`, formData);
            if (res.status === 201) {
                return { email, navigate }
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
)

export const sendCodeForNewPassword = createAsyncThunk(
    'account/sendCodeForNewPassword',
    async ({ code, navigate }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('code', code);
            const res = await api.post('/api/users/password_reset/code/', formData);
            if (res.status === 200) {
                return { res, navigate }
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
)

export const resendCodeForNewPassword = createAsyncThunk(
    'account/resendCodeForNewPassword',
    async ({ email }) => {
        try {
            const formData = new FormData();
            formData.append('email', email);
            const res = await api.post(`/api/users/resend_code/`, formData);
            if (res.status === 200) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
)

export const sendNewPassword = createAsyncThunk(
    'account/sendNewPassword',
    async ({ password, navigate }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('new_password1', password.new_password);
            formData.append('new_password2', password.password_submit);
            const res = await api.put(`/api/users/password_reset/confirm/`, formData);
            console.log(res);
            if (res.status === 200) {
                return { res, navigate }
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err.response.data);
        }
    }
);

export const logoutAccount = createAsyncThunk(
    'account/logoutAccount',
    async () => {
        try {
            const res = await api.post("/api/users/logout_all/");
            console.log(res)
        } catch (err) {
            console.log("Ошибка при входе в систему:", err);
        }
    }
)