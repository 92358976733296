import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./Profile.scss";

import {
  getUserData,
  updateProfile,
} from "../../../store/account/accountActions";
import { getMyPetitions } from "../../../store/petition/petitionActions";
import { getProfileDataFromLS } from "../../../helpers/functions";

import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import VerificationModal from "./VerificationModal/VerificationModal";
import LogoutModal from "./LogoutModal";
import ProfileNavigation from "./ProfileNavigation";
import ProfileSteps from "./ProfileSteps";
import ProfilePicture from "./ProfilePicture";
import ProfileInputField from "./ProfileInputField";
import ProfileBirthInputs from "./ProfileBirthInputs";
import ProfileGenderSelect from "./ProfileGenderSelect";
import ProfileEmailInput from "./ProfileEmailInput";
import ProfilePasswordInput from "./ProfilePasswordInput";
import Notification from "./Notification";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";

const Profile = ({
  myPetitions,
  loading,
  userData,
  years,
  successNotification,
  setSuccessNotification,
  errorNotification,
  setErrorNotification,
}) => {
  const [activeField, setActiveField] = useState(null);
  const [profile, setProfile] = useState({
    image: "",
    first_name: "",
    last_name: "",
    gender: "",
    date: "",
    day: "",
    month: "",
    year: "",
    region: "",
  });

  const [isChangePassword, setIsChangePassword] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [errors, setErrors] = useState({
    image: false,
    first_name: false,
    last_name: false,
    gender: false,
    date: false,
  });

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);

  const [t] = useTranslation("global");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMyPetitions());
    dispatch(getUserData());
  }, []);

  useEffect(() => {
    if (userData) {
      setProfile(userData);
    }
  }, [userData]);
  useEffect(() => {
    if (userData) {
      const { date, ...otherUserData } = userData;
      setProfile((prevProfile) => ({
        ...prevProfile,
        ...otherUserData,
        ...(date && {
          day: date.split("-")[2],
          month: date.split("-")[1],
          year: date.split("-")[0],
        }),
      }));
    }
  }, [userData]);

  useEffect(() => {
    const { email } = getProfileDataFromLS();
    setCurrentEmail(email);
  }, []);

  const updateDate = (day, month, year) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      date: `${year}-${month}-${day}`,
    }));
  };

  const activateInput = (field) => {
    setActiveField(field);
  };

  const handleBlur = () => {
    setActiveField(null);
  };

  useEffect(() => {
    if (activeField) {
      document.getElementById(`${activeField}Input`).focus();
    }
  }, [activeField]);

  const handleEditProfile = (e) => {
    e.preventDefault();
    if (
      !profile.first_name ||
      !profile.last_name ||
      !profile.gender ||
      !profile.date ||
      !profile.region
    ) {
      setErrors({
        first_name: !profile.first_name,
        last_name: !profile.last_name,
        gender: !profile.gender,
        date: !profile.date,
        region: !profile.region,
      });
      return;
    } else {
      dispatch(updateProfile({ profile, navigate }));
      setErrors({
        first_name: false,
        last_name: false,
        gender: false,
        date: false,
        region: false,
      });
    }
  };

  const handleNavigateToVerification = () => {
    if (
      profile.first_name !== "" &&
      profile.last_name !== "" &&
      profile.gender !== null &&
      profile.date !== null &&
      profile.region !== null
    ) {
      navigate("/verification-info");
    } else {
      setVerificationModal(true);
      setErrors({
        first_name: !profile.first_name,
        last_name: !profile.last_name,
        gender: !profile.gender,
        date: !profile.date,
        region: !profile.region,
      });
    }
  };
  return (
    <>
      {successNotification && (
        <Notification
          children={t("profile.notificationSuccess")}
          type="success"
          setSuccessNotification={setSuccessNotification}
          setErrorNotification={setErrorNotification}
        />
      )}
      {errorNotification && (
        <Notification
          children={t("profile.notificationError")}
          type="error"
          setSuccessNotification={setSuccessNotification}
          setErrorNotification={setErrorNotification}
        />
      )}
      {loading ? (
        <div className="loadingContainer">
          <h3>{t("loading.loading")}</h3>
        </div>
      ) : (
        <div className="profile container">
          <ProfileNavigation profile={profile} myPetitions={myPetitions} />
          {profile?.status === "Не верифицирован" && (
            <ProfileSteps
              handleNavigateToVerification={handleNavigateToVerification}
            />
          )}
          <div className="profile__data">
            <ProfilePicture
              profile={profile}
              errors={errors}
              setProfile={setProfile}
            />
            <form>
              <label htmlFor="surname">{t("profile.surname")}</label>
              <ProfileInputField
                label="Surname"
                placeholder={t("profile.surnameInput")}
                value={profile.last_name}
                onBlur={handleBlur}
                onChange={(e) =>
                  setProfile({ ...profile, last_name: e.target.value })
                }
                activeField={activeField}
                activateInput={activateInput}
                error={errors.last_name}
              />
              <label htmlFor="name">{t("profile.name")}</label>
              <ProfileInputField
                label="Name"
                placeholder={t("profile.nameInput")}
                value={profile.first_name}
                onBlur={handleBlur}
                onChange={(e) =>
                  setProfile({ ...profile, first_name: e.target.value })
                }
                activeField={activeField}
                activateInput={activateInput}
                error={errors.first_name}
              />
              <label htmlFor="gender">{t("profile.gender")}</label>
              <ProfileGenderSelect
                profile={profile}
                setProfile={setProfile}
                errors={errors}
                t={t}
              />
              <label htmlFor="birth">{t("profile.birthDate")}</label>
              <ProfileBirthInputs
                profile={profile}
                errors={errors}
                updateDate={updateDate}
                setProfile={setProfile}
                t={t}
                years={years}
              />

              <label htmlFor="region">{t("profile.region")}</label>
              <select
                id="region"
                className="profile__selectRegion"
                value={profile.region || ""}
                onChange={(e) => setProfile({ ...profile, region: e.target.value })}
                onBlur={handleBlur}
              >
                <option value="" disabled>{t("profile.select")}</option>
                <option value="batken">{t("profile.batken")}</option>
                <option value="jalal_abad">{t("profile.jalal_abad")}</option>
                <option value="issyk_kul">{t("profile.issyk_kul")}</option>
                <option value="naryn">{t("profile.naryn")}</option>
                <option value="osh">{t("profile.osh")}</option>
                <option value="talas">{t("profile.talas")}</option>
                <option value="chuy">{t("profile.chuy")}</option>
                <option value="Other">{t("profile.other")}</option>
              </select>



              <label htmlFor="Email" id="email-label">
                Email
              </label>
              <ProfileEmailInput currentEmail={currentEmail} />
              <label htmlFor="password" id="password-label">
                {t("profile.password")}
              </label>
              <ProfilePasswordInput
                setIsChangePassword={setIsChangePassword}
                isChangePassword={isChangePassword}
              />

              <button className="profile__save-btn" onClick={handleEditProfile}>
                {t("profile.saveChanges")}
              </button>
            </form>
          </div>
          {isChangePassword && (
            <ChangePasswordModal setIsChangePassword={setIsChangePassword} />
          )}
          {showLogoutModal && (
            <LogoutModal setShowLogoutModal={setShowLogoutModal} />
          )}
          {deleteAccountModal && (
            <DeleteAccountModal setDeleteAccountModal={setDeleteAccountModal} />
          )}
          {verificationModal && (
            <VerificationModal
              setVerificationModal={setVerificationModal}
              text={
                "Прежде чем перейти к этапу верификации, заполните поля профиля"
              }
            />
          )}
          <button
            className="profile-logout-btn"
            onClick={() => {
              setShowLogoutModal(true);
            }}
          >
            {t("profile.logout")}
          </button>
          <button
            className="profile-logout-btn"
            onClick={() => {
              setDeleteAccountModal(true);
            }}
          >
            {t("profile.del")}
          </button>
        </div>
      )}
    </>
  );
};

export default Profile;
