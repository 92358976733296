import { useEffect, useState } from "react";
import "./Navbar.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkUserLogin, isUserVerified } from "../../../helpers/functions";
import BurgerMenu from "../images/burger-menu.png";
import BurgerMenuClose from "../images/burger-close.png";
import founded_ru from "./assets/founded-ru.png";
import founded_en from "./assets/founded-en.png";
import founded_kg from "./assets/founded-kg.png";
import NavbarVerifyModal from "./NavbarVerifyModal";
import NavbarLogos from "./NavbarLogos";
import NavbarLoginModal from "./NavbarLoginModal";
import NavbarDropDown from "./NavbarDropdown";
import NavbarCreatePetiton from "./NavbarCreatePetition";
import MenuModal from "./MenuModal";
import NavbarItems from "./NavbarItems";
import NavbarLangSelector from "./NavbarLangSelector";

//PROD: Only for production
import ReactGA from 'react-ga4';
//UAT: Nothing

const FOUNDED = {
  ru: founded_ru,
  kg: founded_kg,
  en: founded_en,
};

const Navbar = () => {
  const [t, i18n] = useTranslation("global");
  const [isShowModal, setIsShowModal] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVerifyAccount, setisVerifyAccount] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobLangOpen, setIsMobLangOpen] = useState(false);
  const [euImage, setEuImage] = useState(FOUNDED.ru);
  const [language, setLanguage] = useState("ru");

  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = checkUserLogin();
    setIsLoggedIn(isLoggedIn);
    // PROD:
    //old:
    //ReactGA.initialize('G-KSWCEGWFG4');
    //new:
    ReactGA.initialize('G-EG7GFRXKT5');
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Page View: " + location.pathname });
    // UAT: Nothing
  }, [location.pathname]);

  useEffect(() => {
    if (isUserVerified()) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }, []);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
    setIsMobLangOpen(false);
    setLanguage(lang);
    setEuImage(FOUNDED[lang]);
  };
  return (
    <div className="navbar">
      <div className="navbar__content container">
        <div className="navbar__logo">
          <NavbarLogos euImage={euImage} />
          <div className="navbar__lang">
            <div className="navbar__dropdown">
              <NavbarDropDown
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                language={language}
              />
              {isOpen && (
                <NavbarLangSelector
                  handleChangeLanguage={handleChangeLanguage}
                />
              )}
            </div>
          </div>
        </div>
        <div className="navbar__menu">
          <NavbarItems />
        </div>
        {isShowModal && (
          <NavbarLoginModal setIsShowModal={setIsShowModal} t={t} />
        )}
        <div className="navbar__button">
          <NavbarCreatePetiton
            isLoggedIn={isLoggedIn}
            isVerified={isVerified}
            setisVerifyAccount={setisVerifyAccount}
            setIsShowModal={setIsShowModal}
          />
          <NavLink to={isLoggedIn ? "/profile" : "/login"}>
            <button>
              {isLoggedIn ? t("navbar.profile") : t("navbar.signIn")}
            </button>
          </NavLink>
        </div>
        {isMenuOpen ? (
          <img
            className="navbar__burgerMenu"
            src={BurgerMenuClose}
            alt="close"
            onClick={() => setIsMenuOpen(false)}
          />
        ) : (
          <img
            className="navbar__burgerMenu"
            src={BurgerMenu}
            alt="menu"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        )}
        {isVerifyAccount && (
          <NavbarVerifyModal setisVerifyAccount={setisVerifyAccount} />
        )}
        {isMenuOpen && (
          <MenuModal
            setIsMenuOpen={setIsMenuOpen}
            setisVerifyAccount={setisVerifyAccount}
            setIsShowModal={setIsShowModal}
            isLoggedIn={isLoggedIn}
            isVerified={isVerified}
            isMenuOpen={isMenuOpen}
            isMobLangOpen={isMobLangOpen}
            handleChangeLanguage={handleChangeLanguage}
            setIsMobLangOpen={setIsMobLangOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
